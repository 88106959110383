import { datadogRum } from '@datadog/browser-rum';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CallBackProps, STATUS, Step } from 'react-joyride';

import { atomProductTourToggle } from '@/modules/InsightsModule/atoms/InsightsAtom';
import { TourStep } from '@/modules/InsightsModule/components/InsightsProductTour/components/TourStep';
import { TOUR_STORAGE_KEY_PREFIX } from '@/modules/InsightsModule/constants';

declare global {
  interface Window {
    __emblueMetadata: {
      userId: string;
    };
  }
}

export const useInsightsProductTour = () => {
  const { t } = useTranslation(['insights']);

  const setRunTour = useSetAtom(atomProductTourToggle);

  const checkTourStatus = useCallback(() => {
    const hasSeenTour = localStorage.getItem(
      `${TOUR_STORAGE_KEY_PREFIX}-${window.__emblueMetadata?.userId}`
    );
    return Boolean(hasSeenTour);
  }, []);

  const datadogMetricShowTour = useCallback(() => {
    datadogRum.addAction('insights.productTour.show');
  }, []);

  const startTour = useCallback(
    (delay = 1000) => {
      if (!checkTourStatus()) {
        setTimeout(() => {
          setRunTour(true);
          datadogMetricShowTour();
        }, delay);
      }
    },
    [checkTourStatus, datadogMetricShowTour, setRunTour]
  );

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { status } = data;
      if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
        setRunTour(false);
        localStorage.setItem(
          `${TOUR_STORAGE_KEY_PREFIX}-${window.__emblueMetadata?.userId}`,
          'true'
        );
      }
    },
    [setRunTour]
  );

  const steps: Step[] = [
    {
      target: '.contacts-summary',
      content: (
        <TourStep
          description={t('INSIGHTS_PRODUCT_TOUR.STEPS.ONE.description')}
          title={t('INSIGHTS_PRODUCT_TOUR.STEPS.ONE.title')}
        />
      ),
      disableBeacon: true,
      placement: 'right',
    },
    {
      target: '.contacts-graph',
      content: (
        <TourStep
          description={t('INSIGHTS_PRODUCT_TOUR.STEPS.TWO.description')}
          title={t('INSIGHTS_PRODUCT_TOUR.STEPS.TWO.title')}
        />
      ),
      placement: 'left',
    },
    {
      target: '.channel-summary',
      content: (
        <TourStep
          description={t('INSIGHTS_PRODUCT_TOUR.STEPS.THREE.description')}
          title={t('INSIGHTS_PRODUCT_TOUR.STEPS.THREE.title')}
        />
      ),
      placement: 'top-start',
      isFixed: true,
    },
    {
      target: '.actions-summary',
      content: (
        <TourStep
          description={t('INSIGHTS_PRODUCT_TOUR.STEPS.FOUR.description')}
          title={t('INSIGHTS_PRODUCT_TOUR.STEPS.FOUR.title')}
        />
      ),
      placement: 'top-start',
      isFixed: true,
    },
  ];

  return {
    events: {
      handleJoyrideCallback,
      startTour,
    },
    state: {
      steps,
    },
  };
};
