import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockModal } from '@/lib/components';
import { Button, If, toast } from '@/lib/v2/components';
import { CloseIcon } from '@/lib/v2/icons/outline/CloseIcon';

import { useMutationUserContext } from '@/src/application/context/UserContext';
import { UserData } from '@/src/application/hooks/useUserDataInterface';
import { IEmblueService } from '@/src/infrastructure/Protocol/IEmblueService';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { IContractPaymentInfo } from '@/src/presentation/types/interfaces/IContractPaymentInfo';
import { LogoutIcon } from '@/src/presentation/util/icon/LogoutIcon';

import { useBillingPortal } from '@/modules/MyPlanModule/hooks/useBillingPortal';

export const BlockAndSuspendModal = ({
  paymentInfo,
  userData,
}: {
  paymentInfo: IContractPaymentInfo;
  userData: UserData;
}) => {
  const { logout } = useMutationUserContext();
  const [showModal, setShowModal] = useState<boolean>(
    paymentInfo.isBlocked || paymentInfo.isSuspended
  );
  const { t } = useTranslation();

  const { handleRedirectBillingPortal, isLoading: billingPortalIsLoading } = useBillingPortal();

  const mailtoHandler = (
    administrationEmail: string | undefined,
    contractId: number | undefined,
    businessName: string | undefined
  ) => {
    /* cspell:disable */
    window.open(
      `mailto:${administrationEmail}?Subject=Informar Pago - ${businessName} - ${contractId}`
    );
  };

  const payBillHandler = (payUrl: string | undefined) => {
    window.open(payUrl);
  };

  const alreadyPaidBillHandler = (service: IEmblueService | undefined) => {
    service?.setAlreadyPaidBill({
      contractId: paymentInfo.contractId,
      userEmail: userData.email,
      emailAdministration: paymentInfo.administrationEmail,
      businessName: paymentInfo.businessName,
    });
    closeHandler();
    toast({
      title: t('MODAL_BLOCK_SUSPENDED.notificationTitle'),
      body: t('MODAL_BLOCK_SUSPENDED.notificationBody'),
      variant: 'success',
    });
  };

  const closeHandler = () => {
    removeStylesToAppContainer();
    setShowModal(false);
  };

  const removeStylesToAppContainer = () => {
    document.body.style.overflow = '';
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const element: any = document.getElementsByClassName('_app__container')[0];
    if (element) {
      element.style.filter = '';
      element.style.pointerEvents = '';
    }
    const iframe = document.getElementById('v1-container');
    if (iframe) {
      iframe.style.filter = '';
      iframe.style.pointerEvents = '';
    }
  };
  const applyStylesToAppContainer = () => {
    document.body.style.overflow = 'hidden';
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const element: any = document.getElementsByClassName('_app__container')[0];
    if (element) {
      element.style.filter = 'brightness(0.5)';
      element.style.pointerEvents = 'none';
    }
    const iframe = document.getElementById('v1-container');
    if (iframe) {
      iframe.style.filter = 'brightness(0.5)';
      iframe.style.pointerEvents = 'none';
    }
  };
  const service = useService();
  useEffect(() => {
    if (showModal) {
      applyStylesToAppContainer();
    }
  }, [showModal]);

  const notAllowCloseModal = useMemo(() => {
    return paymentInfo.isBlocked && !userData.email.includes('@embluemail.com');
  }, [paymentInfo, userData]);

  return !showModal ? (
    <div></div>
  ) : (
    <BlockModal>
      <BlockModal.Body>
        <div className="px-12 pt-8">
          <BlockModal.Title>
            <>
              {t('MODAL_BLOCK_SUSPENDED.Title')}
              <span className="text-emblue-blue-dark"> emBlue</span>{' '}
              {t('MODAL_BLOCK_SUSPENDED.Title1')}
            </>
          </BlockModal.Title>
          <p className="w-11/12 text-[1.3em] text-[#919296]">
            {t('MODAL_BLOCK_SUSPENDED.Body')}
            <p className="pt-4">
              {t('MODAL_BLOCK_SUSPENDED.Communicate')}
              <a
                className="cursor-pointer text-emblue-blue-dark hover:underline"
                onClick={() =>
                  mailtoHandler(
                    paymentInfo.administrationEmail,
                    paymentInfo.contractId,
                    paymentInfo.businessName
                  )
                }
              >
                {' '}
                {paymentInfo.administrationEmail}.
              </a>
            </p>
          </p>
        </div>
      </BlockModal.Body>
      <BlockModal.SideBar>
        <button
          className="absolute right-8 top-5 cursor-pointer hover:bg-slate-300"
          id="logout-button"
          onClick={() => (notAllowCloseModal ? void logout() : closeHandler())}
        >
          {notAllowCloseModal ? <LogoutIcon /> : <CloseIcon height="20.95" width="20.95" />}
        </button>
        <div className="pt-2 text-21 font-medium">{t('MODAL_BLOCK_SUSPENDED.PayAmount')}</div>
        <If condition={!paymentInfo.hasStripeSubscription}>
          <div className="flex h-14 items-center space-x-3">
            <div className="text-[40px] font-bold ">
              {paymentInfo.currencySymbol}
              {paymentInfo.amount}
            </div>
            <div className="text-18 text-[#919296]">{paymentInfo.currency}</div>
          </div>
        </If>
        <div className="w-64">
          <If condition={paymentInfo.isBlocked && !paymentInfo.isSuspended}>
            <Button
              fullWidth
              tertiary
              id="go-to-pay-bill-suspended"
              onClick={() =>
                !paymentInfo.hasStripeSubscription
                  ? payBillHandler(paymentInfo.payUrl)
                  : void handleRedirectBillingPortal()
              }
            >
              {t('MODAL_BLOCK_SUSPENDED.PayBill')}
            </Button>
          </If>
          <If condition={paymentInfo.isSuspended && !paymentInfo.isBlocked}>
            <Button
              fullWidth
              primary
              id="go-to-pay-bill-blocked"
              isLoading={billingPortalIsLoading}
              onClick={() =>
                !paymentInfo.hasStripeSubscription
                  ? payBillHandler(paymentInfo.payUrl)
                  : void handleRedirectBillingPortal()
              }
            >
              {t('MODAL_BLOCK_SUSPENDED.PayBill')}
            </Button>
          </If>
        </div>
        <>
          {paymentInfo.showAlreadyPaidButton && (
            <button
              className="pt-2 font-medium text-emblue-blue-dark underline"
              onClick={() => alreadyPaidBillHandler(service)}
            >
              {t('MODAL_BLOCK_SUSPENDED.billAlreadyPaid')}
            </button>
          )}
        </>
      </BlockModal.SideBar>
    </BlockModal>
  );
};
