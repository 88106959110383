import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { toast } from '@/lib/v2/components';

import { useStateUserContext } from '@/src/application/context/UserContext';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { formValidations } from '@/src/constants/FormValidations';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  atomDetailCampaign,
  atomInfoCampaignV1,
} from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomActionInputModalIsOpen,
  atomActionTypeSelected,
  atomRestrictions,
} from '@/src/modules/CampaignsModule/atoms/createActionsAtom';
import { ACTION_CREATE_MESSAGE } from '@/src/modules/CampaignsModule/constants';
import { EResourceType } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { ActionTypeName } from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { convertValuesToString } from '@/src/utils/Objects';

import { useCampaignActions } from './useCampaignActions';
import { useValidNameResource } from './useValidNameResource';

import { handleInvalidMFA } from '@/modules/AuthModule/hooks/useMfa';
import { ACTION_CREATED } from '@/modules/ContactsModule/constants';

export const useCreateAction = (campaignId: string) => {
  const service = useService();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const createSimpleEmail = useFeatureFlag('createSimpleEmail');

  const setActionInputModalIsOpen = useSetAtom(atomActionInputModalIsOpen);
  const setInfoCampaignV1 = useSetAtom(atomInfoCampaignV1);
  const [restrictions, setRestrictions] = useAtom(atomRestrictions);
  const { name: campaignName } = useAtomValue(atomDetailCampaign);
  const actionTypeSelected = useAtomValue(atomActionTypeSelected);
  const [createActionIsLoading, setCreateActionIsLoading] = useState(false);

  const { userData } = useStateUserContext();
  const { fetchServiceValidName } = useValidNameResource();
  const { editAction } = useCampaignActions();

  const { formRegExp, inputLengths } = formValidations;

  const updateActionTypeForEmailAction = useCallback(
    async (actionId: number, actionName: string, actionType: ActionTypeName) => {
      if (!createSimpleEmail) return;
      await editAction({
        name: actionName,
        campaignElementId: actionId,
        ...(actionType === 'email-split' && { originId: 5 }),
        ...(actionType === 'email-remarketing' && { htmlOriginId: 7 }),
        showToast: false,
      });
    },
    [createSimpleEmail, editAction]
  );

  const VALIDATION_RULES = useMemo(
    () => ({
      input: {
        required: {
          value: true,
          message: t('FORMS_RULES_MESSAGES.required'),
        },
        minLength: {
          value: inputLengths.min,
          message: t('FORMS_RULES_MESSAGES.minLength'),
        },
        maxLength: {
          value: inputLengths.max,
          message: t('FORMS_RULES_MESSAGES.maxLength'),
        },
        pattern: {
          value: formRegExp.lettersNumbersAndSpecials,
          message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
        },
      },
    }),
    [formRegExp.lettersNumbersAndSpecials, inputLengths.max, inputLengths.min, t]
  );

  const handleValidation = useCallback(
    async (value: string) => {
      setCreateActionIsLoading(true);
      const isValidName = await fetchServiceValidName({
        name: value,
        resource: EResourceType.ACTION,
        campaignId: Number(campaignId),
      });
      setCreateActionIsLoading(false);
      return {
        isValidInput: isValidName,
        message: t('CAMPAIGN_ACTIONS_MAIN.existAction'),
      };
    },
    [campaignId, fetchServiceValidName, t]
  );

  const handleCloseModal = useCallback(() => {
    setActionInputModalIsOpen(false);
  }, [setActionInputModalIsOpen]);

  const handleSubmitData = useCallback(
    async (actionName: string) => {
      setCreateActionIsLoading(true);
      actionName = actionName.trim();

      try {
        const response = await service.createCampaignAction(
          actionTypeSelected === 'whatsapp' ? `whatsapp-${actionName}` : actionName, //TODO: temporarily whatsapp actionName has to have whatsapp prefix
          actionTypeSelected === 'whatsapp' ? 'sms' : actionTypeSelected, //TODO: temporarily whatsapp actionType is sms
          campaignId ?? ''
        );

        if (response.message === ACTION_CREATE_MESSAGE) {
          const actionId = response.id;

          if (actionTypeSelected === 'email-split' || actionTypeSelected === 'email-remarketing') {
            await updateActionTypeForEmailAction(actionId, actionName, actionTypeSelected);
          }

          const userDataStr = convertValuesToString(userData);
          const { name, email, plan, contractId } = userDataStr;
          await service.sendEventTrack('actions', {
            names: name as string,
            email: email as string,
            plan: plan as string,
            contractId: contractId as string,
            type: ACTION_CREATED,
          });

          toast({
            title: t('CREATE_ACTIONS_MODAL.NOTIFICATION.actionCreateSuccess.title'),
            body: t('CREATE_ACTIONS_MODAL.NOTIFICATION.actionCreateSuccess.body'),
          });

          setInfoCampaignV1({
            campaign: {
              id: parseInt(campaignId || '0'),
              name: campaignName ?? '',
            },
            action: {
              id: Number(actionId),
              name: actionName,
            },
            route: `/v2/campaigns/${campaignId}`,
          });
          navigate(`${actionTypeSelected}/${actionId}`);
        }
      } catch (e) {
        if ((e as Error).message === 'INVALID_MFA_TOKEN') {
          handleInvalidMFA(t);
        } else {
          toast({
            variant: 'error',
            title: t('CREATE_ACTIONS_MODAL.NOTIFICATION.actionCreateError.title'),
            body: (e as Error).message,
          });
        }
      }
      setCreateActionIsLoading(false);
      setActionInputModalIsOpen(false);
    },
    [
      service,
      campaignId,
      userData,
      campaignName,
      actionTypeSelected,
      setActionInputModalIsOpen,
      t,
      setInfoCampaignV1,
      navigate,
      updateActionTypeForEmailAction,
    ]
  );

  const getRestrictions = useCallback(async () => {
    const response = await service.getRestrictions();
    setRestrictions(response);
  }, [service, setRestrictions]);

  useEffect(() => {
    if (restrictions !== null) return;

    void getRestrictions();
  }, [getRestrictions, restrictions]);

  return {
    handleValidation,
    handleSubmitData,
    handleCloseModal,
    VALIDATION_RULES,
    createActionIsLoading,
    restrictions,
  };
};
