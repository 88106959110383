import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextSkeleton } from '@/lib/components';
import {
  AsleepContactsIcon,
  FrequentContactsIcon,
  InactiveContactsIcon,
  NewContactsIcon,
} from '@/lib/v2/icons/solid';

import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import CardContacts from '@/src/modules/InsightsModule/components/CardContacts';

import {
  ICardContacts,
  IContactsMetric,
} from '@/modules/InsightsModule/interfaces/ContactsSummary';

interface IProps {
  metrics: IContactsMetric;
}

const ContactStatus = (props: IProps) => {
  const { metrics } = props;
  const { t } = useTranslation(['insights']);

  const cardsContacts: ICardContacts[] = useMemo(
    () => [
      {
        id: 'insights-contacts-new',
        icon: <NewContactsIcon />,
        bgIcon: 'bg-emblue-green-light',
        linkTo: '/v2/contacts?filterStatus=new',
        total:
          typeof metrics?.new?.total === 'number' ? (
            quantifier(metrics?.new?.total) || 0
          ) : (
            <TextSkeleton size="sm" />
          ),
        wordings: {
          title: t('contacts.metrics.new.title'),
          description: t('contacts.metrics.new.description'),
          tooltip: '',
        },
      },
      {
        id: 'insights-contacts-frequent',
        icon: <FrequentContactsIcon />,
        bgIcon: 'bg-emblue-sky-blue',
        linkTo: '/v2/contacts?filterStatus=frequent',
        total:
          typeof metrics?.frequent?.total === 'number' ? (
            quantifier(metrics?.frequent?.total) || 0
          ) : (
            <TextSkeleton size="sm" />
          ),
        wordings: {
          title: t('contacts.metrics.frequents.title'),
          description: t('contacts.metrics.frequents.description'),
          tooltip: '',
        },
      },
      {
        id: 'insights-contacts-asleep',
        icon: <AsleepContactsIcon />,
        bgIcon: 'bg-emblue-sleepy-hover',
        linkTo: '/v2/contacts?filterStatus=asleep',
        total:
          typeof metrics?.asleep?.total === 'number' ? (
            quantifier(metrics?.asleep?.total) || 0
          ) : (
            <TextSkeleton size="sm" />
          ),
        wordings: {
          title: t('contacts.metrics.asleep.title'),
          description: t('contacts.metrics.asleep.description'),
          tooltip: '',
        },
      },
      {
        id: 'insights-contacts-inactive',
        icon: <InactiveContactsIcon />,
        bgIcon: 'bg-emblue-secondary-hover',
        linkTo: '/v2/contacts?filterStatus=inactive',
        total:
          typeof metrics?.inactive?.total === 'number' ? (
            quantifier(metrics?.inactive?.total) || 0
          ) : (
            <TextSkeleton size="sm" />
          ),
        wordings: {
          title: t('contacts.metrics.inactive.title'),
          description: t('contacts.metrics.inactive.description'),
          tooltip: '',
        },
      },
    ],
    [metrics, t]
  );

  return (
    <div className="mb-4 grid w-full grid-cols-2 gap-4">
      {cardsContacts.map((item: ICardContacts, key: number) => (
        <CardContacts
          key={key}
          bgIcon={item.bgIcon}
          className="px-4 py-2"
          icon={item.icon}
          id={item.id}
          linkTo={item.linkTo}
          total={item.total}
          wordings={item.wordings}
        />
      ))}
    </div>
  );
};

export default ContactStatus;
