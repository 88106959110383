import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { TabItemProps } from '@/lib/v2/components/Tab/TabItem';
import { TabPaneProps } from '@/lib/v2/components/Tab/TabPane';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { useUserData } from '@/src/infrastructure/services/useUserData';
import { ActionsTabPaneContainer } from '@/src/modules/CampaignsModule/components/CampaignActions/CreateActionsModal/components/ActionsTabPaneContainer';
import { RulesTabPaneContainer } from '@/src/modules/CampaignsModule/components/CampaignActions/CreateActionsModal/components/RulesTabPaneContainer';

import { atomCreateRules } from '@/modules/CampaignsModule/atoms/createActionsAtom';
import { useCreateAction } from '@/modules/CampaignsModule/hooks/useCreateAction';
import { ERulesActionType } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { ActionTypeName } from '@/modules/CampaignsModule/interfaces/Campaigns';
import { MY_PLAN_PATHS } from '@/modules/MyPlanModule/constants';

interface TabsPanesParams {
  onClose?: () => void;
}

export const useTabsPanes = ({ onClose }: TabsPanesParams) => {
  const { t } = useTranslation();
  const enableWhatsAppModule = useFeatureFlag('enableWhatsAppModule');
  const service = useService();
  const navigate = useNavigate();
  const { id: campaignId } = useParams();
  const [userData] = useUserData();

  const createRules = useAtomValue(atomCreateRules);

  const { restrictions } = useCreateAction(campaignId ?? '');

  const clickLabel = useCallback(
    (action: ActionTypeName) => {
      if (!userData.email.includes('@embluemail.com')) void service.sendUpgradeInterest(action);
      navigate(`/v2/${MY_PLAN_PATHS.MODULE_PATH}/${MY_PLAN_PATHS.MANAGE_PLAN}`);
    },
    [service, userData.email, navigate]
  );

  const { tabItems, tabItemsRules } = useMemo<{
    tabItems: TabItemProps[];
    tabItemsRules: TabItemProps[];
  }>(() => {
    const tabItems = [
      {
        children: t('CREATE_ACTIONS_MODAL.alls'),
        tabId: 1,
        id: 'all-tab',
      },
      {
        children: t('CREATE_ACTIONS_MODAL.emails'),
        tabId: 2,
        id: 'emails-tab',
      },
      {
        children: t('CREATE_ACTIONS_MODAL.sms'),
        tabId: 3,
        id: 'sms-tab',
      },
    ];

    if (enableWhatsAppModule) {
      tabItems.push({
        children: t('CREATE_ACTIONS_MODAL.inbox'),
        tabId: 4,
        id: 'inbox-tab',
      });
    }

    const tabItemsRules = [
      {
        children: t('ALL_CARDS_ACTIONS.RULES_TYPES.tab'),
        tabId: 1,
        id: 'all-rules',
      },
    ];

    return { tabItems, tabItemsRules };
  }, [t, enableWhatsAppModule]);

  const { tabPanes, tabPanesRules } = useMemo<{
    tabPanes: TabPaneProps[];
    tabPanesRules: TabPaneProps[];
  }>(() => {
    const tabPanes = [
      {
        tabId: 1,
        children: (
          <ActionsTabPaneContainer
            restrictions={restrictions}
            onClose={onClose}
            onLabelClick={clickLabel}
          />
        ),
      },
      {
        tabId: 2,
        children: (
          <ActionsTabPaneContainer
            filterBy="email"
            restrictions={restrictions}
            onClose={onClose}
            onLabelClick={clickLabel}
          />
        ),
      },
      {
        tabId: 3,
        children: (
          <ActionsTabPaneContainer
            filterBy="sms"
            restrictions={restrictions}
            onClose={onClose}
            onLabelClick={clickLabel}
          />
        ),
      },
    ];

    if (enableWhatsAppModule) {
      tabPanes.push({
        tabId: 4,
        children: (
          <ActionsTabPaneContainer
            filterBy="inbox"
            restrictions={restrictions}
            onClose={onClose}
            onLabelClick={clickLabel}
          />
        ),
      });
    }

    const tabPanesRules = [
      {
        tabId: 1,
        children: <RulesTabPaneContainer onClose={onClose} />,
      },
    ];

    return { tabPanes, tabPanesRules };
  }, [clickLabel, onClose, restrictions, enableWhatsAppModule]);

  const tabItemsGroup = useMemo(
    () => (createRules === ERulesActionType.ALL ? tabItems : tabItemsRules),
    [createRules, tabItems, tabItemsRules]
  );

  const tabPanesGroup = useMemo(
    () => (createRules === ERulesActionType.ALL ? tabPanes : tabPanesRules),
    [createRules, tabPanes, tabPanesRules]
  );

  return {
    state: {
      tabItems: tabItemsGroup,
      tabPanes: tabPanesGroup,
    },
  };
};
