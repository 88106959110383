import { useCallback, useState } from 'react';

import { useRulesService } from '@/src/infrastructure/Protocol/Rules/useRulesService';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { DEFAULT_PAYLOAD_RULE } from '@/src/modules/RulesModule/constants';
import { PayloadSaveRules, RuleTypes } from '@/src/modules/RulesModule/interfaces';

import { ActionTypeName } from '@/modules/CampaignsModule/interfaces/Campaigns';

interface CreateRuleParams {
  ruleType: RuleTypes;
  campaignId: string;
  actionName: string;
  actionType: Extract<ActionTypeName, 'email-trigger'>;
}

const useCreateRule = () => {
  const service = useService();
  const rulesService = useRulesService();
  const [isLoading, setIsLoading] = useState(false);

  /* cspell:disable */
  const createDefaultRule = useCallback(
    async (actionId: number, ruleType: RuleTypes) => {
      if (!ruleType) return;

      const payloadSave = {
        ...DEFAULT_PAYLOAD_RULE,
        elementoId: actionId,
        fechaEnvio:
          ruleType === RuleTypes.DATE || ruleType === RuleTypes.TAG
            ? '1|mes|antes|dia'
            : 'de inmediato',
        desencadenante: ruleType,
      } as PayloadSaveRules;

      await rulesService.saveRule(payloadSave);
    },
    [rulesService]
  );
  /* cspell:enable */

  const createRule = useCallback(
    async (params: CreateRuleParams): Promise<number> => {
      const { campaignId, actionName, ruleType, actionType } = params;

      try {
        setIsLoading(true);
        const response = await service.createCampaignAction(actionName, actionType, campaignId);
        await createDefaultRule(response.id, ruleType);
        return response.id;
      } finally {
        setIsLoading(false);
      }
    },
    [createDefaultRule, service]
  );

  return {
    events: {
      createRule,
    },
    state: {
      loading: isLoading,
    },
  };
};

export default useCreateRule;
