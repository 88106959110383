import classNames from 'classnames';
import { memo } from 'react';

import { Card, IconSvg, Text } from '@/lib/v2/components';
import { OpenNewWindow } from '@/lib/v2/icons/outline';

import { ICardContacts } from '@/modules/InsightsModule/interfaces/ContactsSummary';

type CardContentProps = Omit<ICardContacts, 'linkTo'> & {
  className: string;
};

const CardContent = ({ wordings, icon, bgIcon, total, className }: CardContentProps) => {
  const contentClassNames = classNames({
    'w-5/6': icon,
    'w-full': !icon,
  });

  const cardClassNames = classNames('flex !flex-row items-center justify-center gap-4', className);
  const iconClassNames = classNames('rounded-full p-4', bgIcon);

  return (
    <Card noPadding noShadow className={cardClassNames} isFullHeight={false}>
      {icon && <div className={iconClassNames}>{icon}</div>}
      <div className={contentClassNames}>
        <div className="flex w-full items-center justify-between">
          <Text className="uppercase" fontWeight="medium" variant="text">
            {wordings?.title}
          </Text>
          <IconSvg bgColor="primary" svgComponent={<OpenNewWindow />} />
        </div>
        <div className="box-content flex w-14">
          {typeof total === 'string' && <Text variant="title-1">{total}</Text>}
          {typeof total !== 'string' && total}
        </div>
        {wordings?.description && (
          <Text className="max-w-56" color="light-black" variant="text-sm">
            {wordings?.description}
          </Text>
        )}
      </div>
    </Card>
  );
};

export default memo(CardContent);
