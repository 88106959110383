import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, If, Tab, TabContent, TabItem, TabNav, TabPane } from '@/lib/v2/components';

import {
  GRAPH_EVOLUTION_MONTHS,
  TRANSLATE_INSIGHTS_CONTACTS,
} from '@/src/modules/InsightsModule/constants';

import { BarGraphContacts } from './components/BarGraphContacts';
import { BarGraphSkeleton } from './components/BarGraphSkeleton';
import { ContactsGraphLegend } from './components/ContactsGraphLegend';
import { useGraphEvolution } from './hooks/useGraphEvolution';

const ContactsGraph = () => {
  const { t } = useTranslation(['insights']);
  const [currentTab, setCurrentTab] = useState<number>(3);

  const {
    state: { loading },
    events: { getGraphEvolutionMonths },
  } = useGraphEvolution();

  useEffect(() => {
    void (async () => await getGraphEvolutionMonths(GRAPH_EVOLUTION_MONTHS.THREE))();
  }, []);

  const handleTabChange = useCallback(
    async (tab: number) => {
      setCurrentTab(tab);
      await getGraphEvolutionMonths(tab);
    },
    [setCurrentTab, getGraphEvolutionMonths]
  );

  return (
    <Card
      noShadow
      className="relative flex w-full justify-between pb-0"
      id="insights-contacts-graph"
    >
      <ContactsGraphLegend />
      <Tab currentTab={currentTab}>
        <TabNav className="flex justify-end" gap="small">
          <TabItem
            id="insights-contacts-graph-tab-three"
            tabId={GRAPH_EVOLUTION_MONTHS.THREE}
            onClick={() => handleTabChange(GRAPH_EVOLUTION_MONTHS.THREE)}
          >
            {t(`${TRANSLATE_INSIGHTS_CONTACTS}.rangeMonths`, {
              months: 3,
            })}
          </TabItem>
          <TabItem
            id="insights-contacts-graph-tab-six"
            tabId={GRAPH_EVOLUTION_MONTHS.SIX}
            onClick={() => handleTabChange(GRAPH_EVOLUTION_MONTHS.SIX)}
          >
            {t(`${TRANSLATE_INSIGHTS_CONTACTS}.rangeMonths`, {
              months: 6,
            })}
          </TabItem>
          <TabItem
            id="insights-contacts-graph-tab-twelve"
            tabId={GRAPH_EVOLUTION_MONTHS.TWELVE}
            onClick={() => handleTabChange(GRAPH_EVOLUTION_MONTHS.TWELVE)}
          >
            {t(`${TRANSLATE_INSIGHTS_CONTACTS}.rangeMonths`, {
              months: 12,
            })}
          </TabItem>
        </TabNav>
        <TabContent>
          <TabPane tabId={GRAPH_EVOLUTION_MONTHS.THREE}>
            <If condition={!loading} fallback={<BarGraphSkeleton />}>
              <BarGraphContacts />
            </If>
          </TabPane>
          <TabPane tabId={GRAPH_EVOLUTION_MONTHS.SIX}>
            <If condition={!loading} fallback={<BarGraphSkeleton />}>
              <BarGraphContacts />
            </If>
          </TabPane>
          <TabPane tabId={GRAPH_EVOLUTION_MONTHS.TWELVE}>
            <If condition={!loading} fallback={<BarGraphSkeleton />}>
              <BarGraphContacts />
            </If>
          </TabPane>
        </TabContent>
      </Tab>
    </Card>
  );
};

export default memo(ContactsGraph);
