import classNames from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';

import { useStateTabContext } from './Tab';

export type TabItemProps = {
  children: ReactNode;
  tabId: number | string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  id?: string;
  lineHeight?: 'small' | 'medium';
  className?: string;
};

const TabItem = ({ children, tabId, onClick, id, lineHeight = 'small' }: TabItemProps) => {
  const { currentTab } = useStateTabContext();

  const active = currentTab === tabId;

  const classes = classNames('inline-block rounded-t-lg py-2 px-4 text-emblue-black', {
    'text-emblue-primary border-b-emblue-primary': active,
    'border-b-2': lineHeight === 'small' && active,
    'border-b-4': lineHeight === 'medium' && active,
  });

  return (
    <li id={id}>
      <button aria-current="page" className={classes} onClick={onClick}>
        {children}
      </button>
    </li>
  );
};

export default TabItem;
