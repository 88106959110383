import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { formValidations } from '@/src/constants/FormValidations';

import { atomRuleConfigCreation } from '@/modules/CampaignsModule/atoms/createActionsAtom';
import {
  IValidationResource,
  useValidNameResource,
} from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { atomToggleCreateRuleModal } from '@/modules/RulesModule/atoms/rules';
import useRuleCreateRule from '@/modules/RulesModule/hooks/useCreateRule';

export const useCreateRuleModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { campaignId } = useParams();

  const { validateNameResource, loadingValidation } = useValidNameResource();
  const {
    events: { createRule },
    state: { loading },
  } = useRuleCreateRule();

  const setToggleCreateRuleModal = useSetAtom(atomToggleCreateRuleModal);
  const ruleConfigCreation = useAtomValue(atomRuleConfigCreation);

  const { formRegExp, inputLengths } = formValidations;

  const RULE_VALIDATION_RULES = useMemo(
    () => ({
      input: {
        required: {
          value: true,
          message: t('FORMS_RULES_MESSAGES.required'),
        },
        minLength: {
          value: inputLengths.min,
          message: t('FORMS_RULES_MESSAGES.minLength'),
        },
        maxLength: {
          value: inputLengths.max,
          message: t('FORMS_RULES_MESSAGES.maxLength'),
        },
        pattern: {
          value: formRegExp.lettersNumbersAndSpecials,
          message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
        },
      },
    }),
    [formRegExp.lettersNumbersAndSpecials, inputLengths.max, inputLengths.min, t]
  );

  const handleCloseRuleModal = useCallback(() => {
    setToggleCreateRuleModal(false);
  }, [setToggleCreateRuleModal]);

  const handleCreateRule = useCallback(
    async (name: string) => {
      if (!campaignId || !ruleConfigCreation) return;

      const currentRuleId = await createRule({
        campaignId,
        actionName: name,
        actionType: ruleConfigCreation.actionType,
        ruleType: ruleConfigCreation.ruleType,
      });

      handleCloseRuleModal();
      navigate(`${ruleConfigCreation.actionType}/${currentRuleId}`);
    },
    [campaignId, ruleConfigCreation, createRule, handleCloseRuleModal, navigate]
  );

  const validateRuleName = useCallback(
    async (value: string, message: string): Promise<IValidationResource> => {
      const isValidResource = await validateNameResource({
        name: value,
        resource: EResourceType.ACTION,
        campaignId: Number(campaignId),
      });

      return { isValidInput: isValidResource, message };
    },
    [validateNameResource, campaignId]
  );

  return {
    events: {
      handleCloseRuleModal,
      handleCreateRule,
      validateRuleName,
    },
    state: {
      processing: loading,
      loadingValidation,
      RULE_VALIDATION_RULES,
    },
  };
};
