import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Text } from '@/lib/v2/components';

import { EmailForm } from './components/EmailForm';
import { MessageCardsList } from './components/EmailForm/components/MessageCardsList';
import { MessageDisplay } from './components/EmailForm/components/MessageDisplay';

import {
  atomActionInfoEmail,
  atomDisableEditAction,
  atomIsActionInfoEmailErrors,
} from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomActionTypePath,
  atomHeaderCurrentStep,
  haveMessageAtom,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import { useSimpleEmail } from '@/modules/CampaignsModule/hooks/useSimpleEmail';
import {
  EActionTypesPath,
  EStepHeaderAction,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';

const EmailAction = () => {
  const { t } = useTranslation();
  const { actionId } = useParams();

  const [currentStep, setCurrentStep] = useAtom(atomHeaderCurrentStep);
  const haveMessage = useAtomValue(haveMessageAtom);
  const disableEditAction = useAtomValue(atomDisableEditAction);

  const setActionTypePath = useSetAtom(atomActionTypePath);
  const setHaveErrors = useSetAtom(atomIsActionInfoEmailErrors);
  const [actionInfoEmail, setActionInfoEmail] = useAtom(atomActionInfoEmail);

  const { cardList } = useSimpleEmail();
  const { getEmailInfo, loading } = useActionEmailData(Number(actionId));

  const { content: messageHTML } = actionInfoEmail || {};

  useLayoutEffect(() => {
    void (async () => await getEmailInfo())();
    return () => setActionInfoEmail(undefined);
  }, [getEmailInfo, setActionInfoEmail]);

  useLayoutEffect(() => {
    if (currentStep !== EStepHeaderAction.FIRST) setCurrentStep(EStepHeaderAction.FIRST);
  }, [currentStep, setCurrentStep]);

  useEffect(() => setActionTypePath(EActionTypesPath.EMAIL), [setActionTypePath]);

  useEffect(() => {
    setHaveErrors(
      messageHTML?.errors?.includes('NonExistentTags') ||
        messageHTML?.errors?.includes('NotEnoughTags') ||
        false
    );
  }, [messageHTML?.errors, setHaveErrors]);

  return (
    <div className="grid w-full grid-cols-2">
      <div className="my-12 bg-white px-10">
        {<EmailForm disableForm={disableEditAction} loading={loading} />}
      </div>
      <div className="bg-emblue-secondary-hover">
        {loading || actionInfoEmail?.content?.message ? (
          <MessageDisplay
            disabled={disableEditAction}
            html={actionInfoEmail?.content}
            isLoading={loading}
          />
        ) : (
          <div className="self-start">
            <div>
              <Text
                className="my-12 text-center"
                color={!haveMessage ? 'primary' : 'alert'}
                fontWeight="medium"
                id="simple-email-create-content-label"
              >
                {t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.createContent`)}
              </Text>
            </div>
            <div>
              <MessageCardsList cardsList={cardList} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailAction;
