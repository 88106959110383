/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosError } from 'axios';

import { IAssignmentRule } from '@/src/apps/chat/components/AssignmentRules/interfaces/assignment.interface';
import { getInboxApiUrl } from '@/src/apps/chat/config/api';
import axiosInstance from '@/src/apps/chat/utils/axiosInstance';
import { IInboxParamStart } from '@/src/infrastructure/interfaces/IInbox.interface';
import { APIService } from '@/src/infrastructure/Protocol/APIService';

import {
  IContactActivityList,
  IEmblueApiMethodNameMap,
  IInboxEvent,
  IInboxEventsPayload,
  IInboxEventsResponse,
  IInboxService,
  OpenNewConversationFromTemplatePayload,
  OpenNewConversationFromTemplateResponse,
  SummaryConversationResponse,
  WhatsAppTemplate,
  WhatsAppTemplateCreate,
  WhatsAppTemplateResponse,
} from './IInboxService';
export class InboxService implements IInboxService {
  private api: APIService;
  private apiInbox: APIService;
  private pathApi = 'api/v2.1';
  private pathApiInbox = 'api/v1';

  private constructor(payload: IInboxParamStart) {
    this.api = new APIService(payload.url_ema_app_v2, payload.jwt);
    this.apiInbox = new APIService(payload.url_inbox_api, payload.jwt);
  }

  /**
   * @desc Catch errors from API
   */
  private catchErrors(error: AxiosError<Error>, method: string): void {
    const { message } = error;
    console.error(`❌ EM_Error InboxService[${method}]:`, message);
  }

  /**
   * @desc Create new instance of AutomationService
   */
  static create(payload: IInboxParamStart): IInboxService {
    return new InboxService(payload);
  }

  /**
   * @desc Get events from inbox
   */
  async getEventsInbox(query: IInboxEventsPayload): Promise<IInboxEventsResponse> {
    try {
      const { data } = await this.api.post<IInboxEvent[]>(
        `${this.pathApi}/contacts/search_inbox_events`,
        query
      );

      const events: IInboxEvent[] = data.map((event) => ({
        id: event.id,
        contactId: event.contactId,
        conversationId: event.conversationId,
        agentId: event.agentId,
        agentName: event.agentName,
        status: event.status,
        channel: event.channel,
        description: event.description,
        createdAt: event.createdAt,
        lastUpdatedAt: event.lastUpdatedAt,
        summary: event.summary,
      }));

      return {
        events,
        total: events.length,
        partial: events.length,
      };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getEventsInbox');
      return { events: [], total: 0, partial: 0 };
    }
  }

  /**
   * @desc Get WhatsApp templates by account
   */
  async getWhatsAppTemplatesByAccount(): Promise<WhatsAppTemplate[]> {
    try {
      const { data } = await this.api.get<WhatsAppTemplate[]>(
        `${this.pathApi}/inbox/whatsapp_templates`
      );

      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getWhatsAppTemplatesByAccount');
      return [];
    }
  }

  /**
   * @desc Open a new conversation with a unique contact from a template
   */
  async openNewConversationFromTemplate(
    query: OpenNewConversationFromTemplatePayload
  ): Promise<OpenNewConversationFromTemplateResponse> {
    try {
      const { data } = await this.api.post<OpenNewConversationFromTemplateResponse>(
        `${this.pathApi}/inbox/open_new_conversation`,
        query
      );

      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'openNewConversationFromTemplate');
      return { success: false, twilioMessageStatus: 'failed' };
    }
  }

  /**
   * @desc Check if an inbox account exists for the current account
   */
  async checkInboxAccountExists(): Promise<boolean> {
    try {
      const { data, status } = await this.api.get(`${this.pathApi}/inbox/check_inbox_account`);

      if (status !== 200) {
        return false;
      }

      return data as boolean;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'checkInboxAccountExists');
      return false;
    }
  }

  /**
   * @desc Get inbox contract status
   */
  async getInboxContractStatus(): Promise<boolean> {
    try {
      const { status } = await this.api.get<boolean>(`${this.pathApi}/inbox/check_contract_status`);

      return status === 200;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getInboxContractStatus');
      return false;
    }
  }

  async createTextTemplate(template: WhatsAppTemplateCreate): Promise<WhatsAppTemplateResponse> {
    try {
      const { data } = await this.api.post<WhatsAppTemplateResponse>(
        `${this.pathApi}/inbox/templates/text`,
        template
      );
      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'createTextTemplate');
      return {};
    }
  }

  async createMediaTemplate(template: WhatsAppTemplateCreate): Promise<WhatsAppTemplateResponse> {
    try {
      const { data } = await this.api.post<WhatsAppTemplateResponse>(
        `${this.pathApi}/inbox/templates/media`,
        template
      );
      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'createMediaTemplate');
      return {};
    }
  }

  async getSummaryConversation(accountId: number): Promise<SummaryConversationResponse | null> {
    try {
      const { data } = await this.api.post<SummaryConversationResponse>(
        `${this.pathApi}/inbox/resume/${accountId}/`
      );
      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'createCTATemplate');
      return null;
    }
  }

  async createCTATemplate(template: WhatsAppTemplateCreate): Promise<WhatsAppTemplateResponse> {
    try {
      const { data } = await this.api.post<WhatsAppTemplateResponse>(
        `${this.pathApi}/inbox/templates/cta`,
        template
      );
      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'createCTATemplate');
      return {};
    }
  }

  async saveAssignmentRule(rule: IAssignmentRule): Promise<boolean> {
    try {
      const { data } = await this.api.post<void>(`${this.pathApi}/inbox/assignment_rules`, rule);
      return true;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'saveAssignmentRule');
      return false;
    }
  }

  async getContactActivityList(contactId: string): Promise<IContactActivityList[]> {
    try {
      /*const { data } = await this.apiInbox.getInbox<IContactActivityList[]>(
        `${this.pathApiInbox}/contacts/${contactId}/activity_list`
      );*/
      const { data } = await axiosInstance.get<IContactActivityList[]>(
        `${getInboxApiUrl()}/${this.pathApiInbox}/contacts/${contactId}/activity_list`
      );
      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getContactActivityList');
      return [];
    }
  }
}

export const InboxServiceMethods: IEmblueApiMethodNameMap = Object.getOwnPropertyNames(
  InboxService.prototype
).reduce((h: any, k: any) => {
  h[k] = k;
  return h;
}, {} as typeof InboxService);
