import { useAtom, useSetAtom } from 'jotai';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Checkbox,
  Flex,
  IconSvg,
  If,
  Input,
  InputTextArea,
  Tooltip,
} from '@/lib/v2/components';
import { InfoIcon } from '@/lib/v2/icons/outline';

import { CountWhatsApp } from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppForm/components/CountWhatsApp';
import {
  MAX_LENGTH_WHATSAPP,
  TEMPLATE_OPTION,
} from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/constants';
import { SubjectButtons } from '@/src/modules/CampaignsModule/components/SubjectButtons';
import {
  EButtonType,
  ISubjectChangeButtonsProps,
} from '@/src/modules/CampaignsModule/components/SubjectButtons/SubjectButtons.interface';
import { TRANSLATE_WHATSAPP_ACTION_FORM } from '@/src/modules/CampaignsModule/constants';

import { useWhatsAppTemplateForm } from './hooks/useWhatsAppTemplateForm';

import {
  atomCurrentWhatsAppMessage,
  atomMediaUrl,
  atomTemplateOption,
} from '@/modules/CampaignsModule/atoms/actionWhatsAppAtom';

interface WhatsAppTemplateInputsProps {
  disabled: boolean;
  loading?: boolean;
  actionId: number;
}

const WhatsAppTemplateInputs = ({ disabled, loading, actionId }: WhatsAppTemplateInputsProps) => {
  const { t } = useTranslation('inbox');

  const [currentMessage, setCurrentMessage] = useAtom(atomCurrentWhatsAppMessage);
  const setTemplateOption = useSetAtom(atomTemplateOption);
  const setMediaUrl = useSetAtom(atomMediaUrl);

  const [showInputMedia, setShowInputMedia] = useState(false);
  const [messageLength, setMessageLength] = useState(0);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { control, RULES, handleSubmitTemplate, setValue, getValues } = useWhatsAppTemplateForm();

  const handleChangeMessage = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setCurrentMessage(event.target.value);
    },
    [setCurrentMessage]
  );

  const insertTextAtCursor = useCallback(
    (text: string) => {
      const textarea = textareaRef.current;
      const formValue = getValues('message') || '';

      if (!textarea) {
        const newMessage = formValue + text;
        setValue('message', newMessage);
        setCurrentMessage(newMessage);
        return;
      }

      const { selectionStart, selectionEnd, value } = textarea;
      const newMessage = value.substring(0, selectionStart) + text + value.substring(selectionEnd);

      setValue('message', newMessage);
      setCurrentMessage(newMessage);

      textarea.focus();
      const cursorPosition = selectionStart + text.length;
      textarea.selectionStart = cursorPosition;
      textarea.selectionEnd = cursorPosition;
    },
    [getValues, setValue, setCurrentMessage]
  );

  const handleChangeSubjectButton = useCallback(
    ({ item }: ISubjectChangeButtonsProps) => {
      insertTextAtCursor(item);
    },
    [insertTextAtCursor]
  );

  const handleChangeMedia = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMediaUrl(event.target.value);
    },
    [setMediaUrl]
  );

  const handleChangeActivateInputMedia = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setShowInputMedia(event.target.checked);
  }, []);

  const handleTitleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const transformedValue = event.target.value
        .toLowerCase()
        .replace(/\s+/g, '_')
        .replace(/[^a-z\d_]/g, '');
      setValue('title', transformedValue);
    },
    [setValue]
  );

  useEffect(() => {
    setMessageLength(currentMessage.length);
  }, [currentMessage]);

  return (
    <div className="flex w-full flex-col gap-4">
      <Input
        control={control}
        name="title"
        placeHolder={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.titleTemplatePlaceholder`)}
        rules={RULES.title}
        type="text"
        onChange={handleTitleChange}
      />

      <div className="-mt-1 w-full">
        <div className="flex gap-4">
          <div className="flex-1">
            <InputTextArea
              ref={textareaRef}
              control={control}
              disabled={disabled}
              id="whatsapp_message"
              isLoading={loading}
              name="message"
              rows={7}
              rules={RULES.message}
              onChange={handleChangeMessage}
            />
            <If condition={!loading}>
              <div className="flex justify-end">
                <CountWhatsApp
                  count={messageLength}
                  length={messageLength}
                  maxLength={MAX_LENGTH_WHATSAPP}
                />
              </div>
            </If>
          </div>

          <div className="mt-1 w-fit">
            <SubjectButtons
              vertical
              actionId={actionId}
              disabled={disabled}
              exclude={[EButtonType.TAG]}
              id="whatsapp_form-buttons"
              searchPlaceholder={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.searchDropdown`)}
              onChangeButtons={handleChangeSubjectButton}
            />
          </div>
        </div>
      </div>

      <div className="mb-4 flex flex-col gap-1">
        <div className="flex w-full items-center justify-start gap-1">
          <Checkbox
            control={control}
            id="activateInputMedia"
            label={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.addImage`)}
            name="activateInputMedia"
            onChange={handleChangeActivateInputMedia}
          />
          <Tooltip
            isFullWidth
            className="flex-nowrap text-nowrap"
            content={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.addImageTooltip`)}
            position="right"
          >
            <IconSvg svgComponent={<InfoIcon />} />
          </Tooltip>
        </div>
        <If condition={showInputMedia}>
          <Input
            control={control}
            name="media"
            placeHolder={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.mediaTemplatePlaceholder`)}
            rules={RULES.media}
            type="text"
            onChange={handleChangeMedia}
          />
        </If>
      </div>

      <Flex withGap alignment="end" gapSize="small" itemAlignment="center">
        <Button
          outline
          secondary
          id="cancel-button"
          size="small"
          onClick={() => setTemplateOption(TEMPLATE_OPTION.IDLE)}
        >
          {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.cancel`)}
        </Button>

        <Button primary id="validate-button" size="small" onClick={handleSubmitTemplate}>
          {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.validate`)}
        </Button>
      </Flex>
    </div>
  );
};

export default WhatsAppTemplateInputs;
