import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { memo, useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';

import { Card } from '@/lib/v2/components';

import {
  atomActionNodeInfo,
  atomIsLoadingRuleData,
  atomRuleTypeSelected,
} from '@/src/modules/RulesModule/atoms/rules';
import SkeletonNode from '@/src/modules/RulesModule/components/Skeleton/SkeletonNode';
import { RuleTypes } from '@/src/modules/RulesModule/interfaces';

import EmailContent from './components/EmailContent';

import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';

const ActionNode = () => {
  const { campaignId, actionId, actionType } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isRulesPath = pathname.includes('automation/rules');

  const isLoadingRuleData = useAtomValue(atomIsLoadingRuleData);
  const actionNodeInfo = useAtomValue(atomActionNodeInfo);
  const ruleTypeSelected = useAtomValue(atomRuleTypeSelected);

  const handleAddAction = useCallback(() => {
    if (isRulesPath) {
      navigate('email');
      return;
    }
    if (campaignId && actionId && actionType) {
      navigate(`${CAMPAIGNS_PATH}/${campaignId}/${actionType}/${actionId}/email`);
    }
  }, [isRulesPath, campaignId, actionId, actionType, navigate]);

  const showBorderAlert = useMemo(() => {
    return actionNodeInfo.partial || ruleTypeSelected?.value === RuleTypes.FLOW_TEMPLATE;
  }, [actionNodeInfo.partial, ruleTypeSelected?.value]);

  const classNameCard = classNames(
    'w-72 !px-4 pb-0 hover:outline-none hover:ring-2 hover:ring-emblue-primary hover:ring-offset-2',
    { '!border-emblue-alert': showBorderAlert }
  );

  return (
    <>
      <Handle className="handle" position={Position.Top} type="target" />
      <Card className={classNameCard} onClick={handleAddAction}>
        {isLoadingRuleData ? <SkeletonNode /> : <EmailContent />}
      </Card>
    </>
  );
};

export default memo(ActionNode);
