import { useCallback, useEffect, useState } from 'react';

import { useInsightsService } from '@/src/infrastructure/Protocol/Insights/useInsightsService';
import { useUserData } from '@/src/infrastructure/services/useUserData';
import {
  IContactsMetric,
  InsightsAdditionalMetricsContacts,
} from '@/src/modules/InsightsModule/interfaces/ContactsSummary';

//TODO: Refactor this hook for unique customHook without useEffects
export const useAdditionalMetrics = () => {
  const [userData] = useUserData();
  const service = useInsightsService();
  const [metrics, setMetrics] = useState<InsightsAdditionalMetricsContacts>(
    {} as InsightsAdditionalMetricsContacts
  );

  const getMetrics = useCallback(async () => {
    const response = await service.getContactsAdditionalMetrics(+userData.companyId);

    if (!response) return;
    setMetrics(response);
  }, [service, userData.companyId]);

  useEffect(() => {
    void getMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    state: {
      metrics,
    },
  };
};

export const useMetrics = () => {
  const [userData] = useUserData();
  const service = useInsightsService();

  const [isLoading, setIsLoading] = useState(false);
  const [metrics, setMetrics] = useState<IContactsMetric>({} as IContactsMetric);

  const getMetrics = useCallback(async () => {
    setIsLoading(true);
    const response = await service.getContactsMetrics(+userData.companyId);

    if (!response) return;

    const newMetrics: IContactsMetric = {} as IContactsMetric;

    for (const metric of response) {
      newMetrics[metric.metricName] = metric;
    }

    setMetrics(newMetrics);
    setIsLoading(false);
  }, [service, userData.companyId]);

  useEffect(() => {
    void getMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    state: {
      metrics,
      loading: isLoading,
    },
  };
};
