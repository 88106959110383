import { IAssignmentRule } from '@/src/apps/chat/components/AssignmentRules/interfaces/assignment.interface';
import { timeout } from '@/src/compat/util';

import {
  IContactActivityList,
  IInboxEventsPayload,
  IInboxEventsResponse,
  IInboxService,
  OpenNewConversationFromTemplatePayload,
  OpenNewConversationFromTemplateResponse,
  SummaryConversationResponse,
  WhatsAppTemplate,
  WhatsAppTemplateCreate,
  WhatsAppTemplateResponse,
} from './IInboxService';
import {
  mockInboxEvents,
  mockOpenNewConversationResponse,
  mockWhatsAppTemplates,
} from './Inbox.mocks';

export class StubInboxService implements IInboxService {
  static create(): IInboxService {
    return new StubInboxService();
  }

  async getEventsInbox(query: IInboxEventsPayload): Promise<IInboxEventsResponse> {
    try {
      await timeout(2000);

      return {
        events: mockInboxEvents,
        total: mockInboxEvents.length,
        partial: mockInboxEvents.length,
      };
    } catch (error) {
      console.error('Error en StubInboxService.getEventsInbox:', error);
      return { events: [], total: 0, partial: 0 };
    }
  }

  async getWhatsAppTemplatesByAccount(): Promise<WhatsAppTemplate[]> {
    try {
      await timeout(2000);
      return mockWhatsAppTemplates;
    } catch (error) {
      console.error('Error en StubInboxService.getWhatsAppTemplatesByAccount:', error);
      return [];
    }
  }

  async openNewConversationFromTemplate(
    query: OpenNewConversationFromTemplatePayload
  ): Promise<OpenNewConversationFromTemplateResponse> {
    try {
      await timeout(2000);
      return mockOpenNewConversationResponse;
    } catch (error) {
      console.error('Error en StubInboxService.openNewConversationFromTemplate:', error);
      return { success: false, twilioMessageStatus: 'failed' };
    }
  }

  async checkInboxAccountExists(): Promise<boolean> {
    await timeout(2000);
    return true;
  }

  async getInboxContractStatus(): Promise<boolean> {
    await timeout(2000);
    return true;
  }

  async createTextTemplate(template: WhatsAppTemplateCreate): Promise<WhatsAppTemplateResponse> {
    await timeout(1000);
    return {};
  }

  async createMediaTemplate(template: WhatsAppTemplateCreate): Promise<WhatsAppTemplateResponse> {
    await timeout(1000);
    return {};
  }

  async getSummaryConversation(accountId: number): Promise<SummaryConversationResponse | null> {
    await timeout(1000);
    return null;
  }

  async createCTATemplate(template: WhatsAppTemplateCreate): Promise<WhatsAppTemplateResponse> {
    await timeout(1000);
    return {};
  }

  async saveAssignmentRule(rule: IAssignmentRule): Promise<boolean> {
    try {
      await timeout(1000);
      return true;
    } catch (error) {
      return false;
    }
  }

  async getContactActivityList(contactId: string): Promise<IContactActivityList[]> {
    await timeout(2000);
    return [];
  }
}
