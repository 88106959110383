import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AUTOMATION_PATHS } from '@/modules/AutomationModuleNew/constants';
import {
  atomActionInfoEmail,
  atomProcessingAction,
} from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { haveMessageAtom } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';
import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import { useResetRulesState } from '@/modules/RulesModule/hooks/useResetRulesState';
import { IRulesEmailFormInfo, IRulesEmailInfoPayload } from '@/modules/RulesModule/interfaces';

export const useRulesEmailFormSubmit = () => {
  const navigate = useNavigate();
  const { campaignId, actionId, actionType } = useParams();
  const { pathname } = useLocation();

  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const setHaveMessage = useSetAtom(haveMessageAtom);
  const setProcessingAction = useSetAtom(atomProcessingAction);

  const { saveInfoAction } = useActionEmailData(Number(actionId));
  const { refetchData } = useResetRulesState();

  const rulePath = useMemo(() => {
    const isRulesPath = pathname.includes(
      `${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.RULES_PATH}`
    );
    const rulesPath = pathname.slice(0, pathname.lastIndexOf('/'));
    const campaignsPath = `${CAMPAIGNS_PATH}/${String(campaignId)}/${String(actionType)}/${String(
      actionId
    )}`;

    return isRulesPath ? rulesPath : campaignsPath;
  }, [actionId, campaignId, actionType, pathname]);

  const handleSubmitData = useCallback(
    async (data: IRulesEmailFormInfo) => {
      if (!actionInfoEmail?.content?.message) {
        setHaveMessage(true);
        return;
      }

      setProcessingAction(true);

      const { sender, subject, firstLine } = data;

      const payload: IRulesEmailInfoPayload = {
        senderId: Number(sender.id),
        subject: subject,
        firstLine: firstLine,
      };

      await saveInfoAction(payload);

      refetchData();

      navigate(`${rulePath}`);
      setProcessingAction(false);
    },
    [
      actionInfoEmail?.content?.message,
      navigate,
      refetchData,
      rulePath,
      saveInfoAction,
      setHaveMessage,
      setProcessingAction,
    ]
  );

  return {
    handleSubmitData,
  };
};
