import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipRenderProps } from 'react-joyride';

import { Button, IconSvg } from '@/lib/v2/components';
import { CloseIcon } from '@/lib/v2/icons/outline';

export type CustomTooltipProps = TooltipRenderProps;

const CustomTooltip = ({
  continuous,
  index,
  step,
  backProps,
  primaryProps,
  skipProps,
  size,
  tooltipProps,
}: CustomTooltipProps) => {
  const { t } = useTranslation(['insights']);

  return (
    <div {...tooltipProps} className="relative max-w-430 rounded-lg bg-white shadow-lg">
      <Button
        {...skipProps}
        onlyIcon
        className="!absolute right-6 top-6"
        data-dd-action-name={`insights.productTour.skip.${step.title}.step${index + 1}`}
        primary={false}
        size="tiny"
      >
        <IconSvg svgComponent={<CloseIcon />} />
      </Button>
      <div className="p-8">
        <article className="mb-6">{step.content}</article>
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-600">
            {index + 1}/<span className="text-14 text-[#B7BEC6]">{size}</span>
          </div>
          <div className="flex items-center gap-2">
            {index > 0 && (
              <Button
                {...backProps}
                link
                data-dd-action-name={`insights.productTour.back.${step.title}.step${index + 1}`}
                size="small"
              >
                {t('INSIGHTS_PRODUCT_TOUR.BUTTON.previous')}
              </Button>
            )}
            <Button
              {...primaryProps}
              gray
              outline
              data-dd-action-name={
                index === size - 1
                  ? 'insights.productTour.finish'
                  : `insights.productTour.next.${step.title}.step${index + 1}`
              }
              size="small"
            >
              {continuous
                ? index === size - 1
                  ? t('INSIGHTS_PRODUCT_TOUR.BUTTON.finish')
                  : t('INSIGHTS_PRODUCT_TOUR.BUTTON.next')
                : t('INSIGHTS_PRODUCT_TOUR.BUTTON.close')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CustomTooltip);
