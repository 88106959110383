import { useAtomValue, useSetAtom } from 'jotai';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Flex, If, Spinner, Text } from '@/lib/v2/components';
import { CardTemplate } from '@/lib/v2/organisms';
import {
  CardTemplateProps,
  CardTemplateStatus,
} from '@/lib/v2/organisms/CardTemplate/CardTemplate';

import {
  WhatsAppCTATemplate,
  WhatsAppTemplate,
  WhatsAppTemplateTypes,
} from '@/src/infrastructure/Protocol/Inbox/IInboxService';
import { useFilteredTemplates } from '@/src/modules/ContactsModule/components/SendWhatsAppModal/hooks/useFilteredTemplates';
import { useOpenNewConversation } from '@/src/modules/ContactsModule/components/SendWhatsAppModal/hooks/useOpenNewConversation';

import {
  CAMPAIGNS_PATH,
  TRANSLATE_PHONE_PREVIEW,
  TRANSLATE_SEND_WHATSAPP_MODAL,
  TRANSLATE_WHATSAPP_ACTION_FORM,
} from '@/modules/CampaignsModule/constants';
import { DateFormat, formatDate } from '@/modules/CampaignsModule/utils';
import {
  atomIsLoadingWhatsAppTemplates,
  atomWhatsAppTemplateSelected,
} from '@/modules/ContactsModule/atoms/WhatsApp';
import { CONTACT_PATH } from '@/modules/ContactsModule/constants';

interface TemplatesContainerProps {
  filterBy?: 'pending' | 'approved' | 'rejected';
  searchTerm?: string;
  onClose?: () => void;
}

const TemplatesContainer = ({
  filterBy = 'approved',
  searchTerm = '',
  onClose,
}: TemplatesContainerProps) => {
  const { t } = useTranslation('inbox');
  const location = useLocation();

  const { openNewConversation } = useOpenNewConversation();
  const { filteredTemplates } = useFilteredTemplates({ filterBy, searchTerm });

  const isLoadingTemplates = useAtomValue(atomIsLoadingWhatsAppTemplates);
  const setAtomWhatsAppTemplateSelected = useSetAtom(atomWhatsAppTemplateSelected);

  const isCampaignPath = location.pathname.includes(CAMPAIGNS_PATH);
  const isContactPath = location.pathname.includes(CONTACT_PATH);

  const onSelectTemplate = useCallback(
    async (template: WhatsAppTemplate) => {
      if (isCampaignPath) {
        setAtomWhatsAppTemplateSelected(template);
      }

      if (isContactPath) {
        await openNewConversation({
          templateContent: template.types['twilio/text']?.body || '',
          templateId: template.sid,
        });
      }

      onClose && onClose();
    },
    [isCampaignPath, isContactPath, onClose, openNewConversation, setAtomWhatsAppTemplateSelected]
  );

  const cardTemplatesList: CardTemplateProps[] = useMemo(
    () =>
      filteredTemplates.map((template: WhatsAppTemplate) => {
        const templateType = Object.keys(template.types)[0] as keyof WhatsAppTemplateTypes;
        const templateData = template.types[templateType];

        const getTemplateDescription = () => {
          if (!templateData) return '';

          let content = '';
          const actions =
            templateType === 'twilio/call-to-action'
              ? (templateData as WhatsAppCTATemplate).actions
                  .map((action) => action.title)
                  .join(', ')
              : '';

          switch (templateType) {
            case 'twilio/media':
              content = `${templateData.body} [${t(`${TRANSLATE_PHONE_PREVIEW}.MEDIA`)}]`;
              break;
            case 'twilio/call-to-action':
              content = `${templateData.body}\n\n${t(
                `${TRANSLATE_WHATSAPP_ACTION_FORM}.actions`
              )}: ${actions}`;
              break;
            default:
              content = templateData.body;
          }

          if (template.variables) {
            Object.entries(template.variables).forEach(([key, value]) => {
              content = content.replace(`{{${key}}}`, value);
            });
          }

          return content;
        };

        return {
          title: template.friendly_name,
          description: getTemplateDescription(),
          id: template.sid,
          buttonText: isCampaignPath
            ? t(`${TRANSLATE_SEND_WHATSAPP_MODAL}.apply`)
            : t(`${TRANSLATE_SEND_WHATSAPP_MODAL}.send`),
          latestUpdate: formatDate(template.date_updated, DateFormat.ShortDate),
          onClick: () => onSelectTemplate(template),
          templateType: templateType.replace('twilio/', ''),
          hasMedia: templateType === 'twilio/media',
          hasActions: templateType === 'twilio/call-to-action',
          status: template.approvalStatus as CardTemplateStatus,
        };
      }),
    [filteredTemplates, isCampaignPath, t, onSelectTemplate]
  );

  return (
    <>
      <If condition={isLoadingTemplates}>
        <Flex alignment="center" className="min-h-[500px]" itemAlignment="center">
          <Spinner withoutOverlay />
        </Flex>
      </If>
      <If condition={!isLoadingTemplates && filteredTemplates.length > 0}>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {cardTemplatesList.map((template) => (
            <CardTemplate key={template.id} {...template} />
          ))}
        </div>
      </If>
      <If condition={!isLoadingTemplates && filteredTemplates.length === 0}>
        <Flex
          column
          withGap
          alignment="center"
          className="min-h-[500px]"
          gapSize="medium"
          id="empty-template-container"
          itemAlignment="center"
        >
          <div className="py-2">
            <Text alignment="center" color="gray" variant="text">
              {t(
                `INBOX.SEND_WHATSAPP_MODAL.TEMPLATES_CONTAINER.NO_${filterBy.toUpperCase()}_TEMPLATES`
              )}
            </Text>
          </div>
        </Flex>
      </If>
    </>
  );
};

export default memo(TemplatesContainer);
