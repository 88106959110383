import { useAtomValue } from 'jotai';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { atomMetricType } from '@/src/modules/InsightsModule/atoms/InsightsAtom';
import { METRICS_HISTORY } from '@/src/modules/InsightsModule/constants';
import { ChannelTrackingType } from '@/src/modules/InsightsModule/interfaces/Channel';

import { transformBouncesToFailed } from '@/modules/InsightsModule/utils';

const ShippingGraphTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation(['insights']);
  const metricType = useAtomValue(atomMetricType);

  if (!active || !payload?.length) return null;

  const data = payload[0].payload as Record<string, string>;
  const metricsToShow = METRICS_HISTORY[metricType as keyof typeof METRICS_HISTORY];

  return (
    <div className="max-w-56 rounded-md border-emblue-subheadings bg-emblue-subheadings p-2">
      <p className="text-14 font-medium leading-tight text-white">
        {t('shipping.metrics.DATE')}: <span className="font-normal">{data.date}</span>
      </p>
      {metricsToShow.map((metric: ChannelTrackingType) => {
        const cleanLabel = transformBouncesToFailed(metric, metricType);
        return (
          <p key={metric} className="text-14 font-medium leading-tight text-white">
            {t(`shipping.metrics.${cleanLabel.toUpperCase()}`)}:{' '}
            <span className="font-normal">{quantifier(data[metric])}</span>
          </p>
        );
      })}
    </div>
  );
};

export default memo(ShippingGraphTooltip);
