import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, TabContent, TabItem, TabNav, TabPane } from '@/lib/v2/components';

import { formatNormalizeLocalizedDate } from '@/src/application/util/formatLocalizedDate';
import { atomShippingFilters } from '@/src/modules/InsightsModule/atoms/InsightsAtom';
import { SHIPPING_RANGE_TOTAL_DAYS } from '@/src/modules/InsightsModule/constants';
import { useShippingMetric } from '@/src/modules/InsightsModule/hooks/useShippingMetrics';

import { ShippingRange } from '@/modules/InsightsModule/interfaces/Channel';

//TODO: Migrate tabs for custom buttons. Unnecessary component
const ShippingRanges = () => {
  const { t } = useTranslation(['insights']);

  const [currentTab, setCurrentTab] = useState<ShippingRange>(ShippingRange.WEEKLY);

  const filters = useAtomValue(atomShippingFilters);

  const {
    events: { updateFilters },
  } = useShippingMetric();

  const getDateRange = (days: number) => {
    const adjustedDays = days - 1;
    const startDate = formatNormalizeLocalizedDate(
      new Date(Date.now() - adjustedDays * 24 * 60 * 60 * 1000)
    ).substring(0, 10);
    const endDate = formatNormalizeLocalizedDate(new Date()).substring(0, 10);

    return { startDate, endDate };
  };

  const handleChangeRange = (type: ShippingRange) => {
    setCurrentTab(type);
    const ranges = getDateRange(SHIPPING_RANGE_TOTAL_DAYS[type]);
    updateFilters({ ...filters, ...ranges });
  };
  return (
    <div className="flex py-3">
      <Tab currentTab={currentTab}>
        <TabNav className="justify-end" gap="medium">
          <TabItem
            id="insights-channel-tab-weekly"
            tabId={ShippingRange.WEEKLY}
            onClick={() => handleChangeRange(ShippingRange.WEEKLY)}
          >
            {t('shipping.ranges.weekly')}
          </TabItem>
          <TabItem
            id="insights-channel-tab-biweekly"
            tabId={ShippingRange.BIWEEKLY}
            onClick={() => handleChangeRange(ShippingRange.BIWEEKLY)}
          >
            {t('shipping.ranges.biweekly')}
          </TabItem>
          <TabItem
            id="insights-channel-tab-monthly"
            tabId={ShippingRange.MONTHLY}
            onClick={() => handleChangeRange(ShippingRange.MONTHLY)}
          >
            {t('shipping.ranges.monthly')}
          </TabItem>
          <TabItem
            id="insights-channel-tab-quarterly"
            tabId={ShippingRange.QUARTERLY}
            onClick={() => handleChangeRange(ShippingRange.QUARTERLY)}
          >
            {t('shipping.ranges.quarterly')}
          </TabItem>
        </TabNav>
        <TabContent>
          <TabPane tabId={1}></TabPane>
          <TabPane tabId={2}></TabPane>
          <TabPane tabId={3}></TabPane>
          <TabPane tabId={4}></TabPane>
        </TabContent>
      </Tab>
    </div>
  );
};

export default ShippingRanges;
