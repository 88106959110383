import { useAtom, useSetAtom } from 'jotai';
import isEqual from 'lodash/isEqual';
import { useCallback } from 'react';

import { useInsightsService } from '@/src/infrastructure/Protocol/Insights/useInsightsService';
import {
  atomChannelTotals,
  atomChannelTracking,
  atomMetricGraph,
  atomMetricType,
  atomShippingFilters,
  atomShippingMetricLoading,
} from '@/src/modules/InsightsModule/atoms/InsightsAtom';
import { InsightsMetricActionsId } from '@/src/modules/InsightsModule/interfaces/ContactsSummary';

import { ChannelTotalType, ShippingFilters } from '@/modules/InsightsModule/interfaces/Channel';

export const useShippingMetric = () => {
  const service = useInsightsService();

  const [filters, setFilters] = useAtom(atomShippingFilters);
  const [loading, setLoading] = useAtom(atomShippingMetricLoading);

  const setChannelTracking = useSetAtom(atomChannelTracking);
  const setChannelTotals = useSetAtom(atomChannelTotals);
  const setMetricType = useSetAtom(atomMetricType);
  const setMetricGraph = useSetAtom(atomMetricGraph);

  const getData = useCallback(
    async (options?: ShippingFilters) => {
      setLoading(true);

      const filtersUpdated = !isEqual(options, filters);

      const response = await service.getActionsMetrics(options ?? filters);
      const { metrics } = response ?? { metrics: { channels: null, tracking: null } };

      setChannelTracking(metrics?.tracking ?? null);
      setChannelTotals(metrics?.channels ?? null);
      setMetricGraph(response?.history ?? []);

      if (filtersUpdated) setFilters(options ?? filters);
      setLoading(false);
    },
    [service, filters, setChannelTracking, setChannelTotals, setMetricGraph, setFilters, setLoading]
  );

  const changeMetricChannel = useCallback(
    async (newMetric: ChannelTotalType) => {
      const metricId = InsightsMetricActionsId[newMetric];
      setMetricType(newMetric);
      await getData({ ...filters, filterType: metricId });
    },
    [filters, getData, setMetricType]
  );

  const updateFilters = useCallback(
    (options: ShippingFilters) => {
      void getData(options);
    },
    [getData]
  );

  return {
    events: {
      getData,
      changeMetricChannel,
      updateFilters,
    },
    state: {
      loading,
    },
  };
};
