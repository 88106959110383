import { useCallback, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUserData } from '@/src/application/hooks/useUserData';
import DateConverter from '@/src/application/util/DateConverter';

import { MAX_SIZE_SUBJECT, TRANSLATE_RULES_EMAIL } from '@/modules/CampaignsModule/constants';

export type SchedulingFormState = {
  scheduledDeliveryDate: Date | null;
  scheduledDeliveryTime: Date | null;
  reinforcementSubject: string;
  reinforcementDate: Date | null;
  reinforcementTime: Date | null;
  reinforcementCheckbox: boolean;
};

const useSchedulingForm = () => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    resetField,
    reset,
    clearErrors,
    trigger,
  } = useForm<SchedulingFormState>();

  const reinforcementCheckbox = watch('reinforcementCheckbox');
  const [userData] = useUserData();
  const formatGMT = useMemo(
    () => (userData.timeOffset ? `GMT${userData.timeOffset}` : undefined),
    [userData.timeOffset]
  );
  const validateDateProgramming = useCallback(
    (date: Date, time: Date): boolean => {
      if (!date || !time || !formatGMT) return false;
      const currentDate = DateConverter.toGMTDate(new Date().toISOString(), formatGMT);
      const dateString = date.toISOString().split('T')[0];
      const timeString = `${time.getHours().toString().padStart(2, '0')}:${time
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${time.getSeconds().toString().padStart(2, '0')}`;
      const combinedDateTime = new Date(`${dateString}T${timeString}`);
      return combinedDateTime > currentDate;
    },
    [formatGMT]
  );
  const validatePastDate = (date: Date | null, time: Date | null) => {
    const isValid = validateDateProgramming(date ?? new Date(), time ?? new Date());
    return isValid ? true : t(`${TRANSLATE_RULES_EMAIL}.datePastError`);
  };

  const RULES = {
    reinforcementSubject: {
      required: reinforcementCheckbox ? t(`${TRANSLATE_RULES_EMAIL}.subject`) : false,
      maxLength: {
        value: MAX_SIZE_SUBJECT,
        message: t(`${TRANSLATE_RULES_EMAIL}.maxLength`, { max: MAX_SIZE_SUBJECT }),
      },
    },
    scheduledDeliveryDate: {
      required: t(`${TRANSLATE_RULES_EMAIL}.date`),
      validate: (value: Date | null, form: FieldValues) =>
        validatePastDate(value, form['scheduledDeliveryTime']),
    },
    scheduledDeliveryTime: {
      required: t(`${TRANSLATE_RULES_EMAIL}.time`),
      validate: (value: Date | null, form: FieldValues) =>
        validatePastDate(form['scheduledDeliveryDate'], value),
    },
    reinforcementDate: {
      required: reinforcementCheckbox ? t(`${TRANSLATE_RULES_EMAIL}.date`) : false,
      validate: (value: Date | null, form: FieldValues) =>
        value ? validatePastDate(value, form['reinforcementTime']) : true,
    },
    reinforcementTime: {
      required: reinforcementCheckbox ? t(`${TRANSLATE_RULES_EMAIL}.time`) : false,
      validate: (value: Date | null, form: FieldValues) =>
        value ? validatePastDate(form['reinforcementDate'], value) : true,
    },
  };

  return {
    getValues,
    control,
    RULES,
    setValue,
    watch,
    reset,
    handleSubmit,
    resetField,
    clearErrors,
    trigger,
  };
};

export default useSchedulingForm;
